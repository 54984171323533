import { Box, Divider, Typography } from "@mui/material";
import { useMainData, useProducts } from "~/hooks";
import { NO_VARIATION } from "~/store";
import CartCounter from "./CartCounter";
import DtwImage from "./DtwImage";

interface CartListItemProps {
  productId: string;
  variationId: string;
}

export default function CartListItem({
  productId,
  variationId,
}: CartListItemProps) {
  const { logo } = useMainData();
  const productList = useProducts();

  const product = productList.find((p) => p.id === productId);
  const variation =
    variationId !== NO_VARIATION
      ? product?.variations.find((v) => v.id === variationId)
      : null;

  if (!product) {
    return <></>;
  }

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", width: 1 }}>
        <DtwImage
          sx={{
            width: 80,
            height: 142,
            marginRight: (t) => t.spacing(1),
          }}
          picture={product.pictures.length > 0 ? product.pictures[0] : logo}
          alt={
            product.pictures.length > 0
              ? product.pictures[0].alt
              : `Picture for ${product.name}`
          }
        />
        <div style={{ flexGrow: 1, width: "100%" }}>
          <Typography
            variant="body1"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            width={1}
            display="inline-block"
            overflow="hidden"
          >
            {product.name}
          </Typography>
          {!!variation && (
            <Typography variant="body2">{variation.name}</Typography>
          )}
          <Typography
            variant="body2"
            sx={{ marginBottom: (t) => t.spacing(1) }}
          >
            {variation?.currentPrice || product.currentPrice} {product.currency}
          </Typography>
          <Box sx={{ marginRight: (t) => t.spacing(2), flex: 1 }}>
            <CartCounter
              product={product}
              variationId={variationId}
            ></CartCounter>
          </Box>
        </div>
      </Box>

      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
    </div>
  );
}
